// Include default ABWeb CMS public styles
@import '../../../../../../webroot/assets/src/less/default.less';
// Custom variables
@import (optional) 'variables.less';
// Animations
@import (optional) 'animations.less';

@import 'colorbox.less';

/* -- Add site-specific styling below -- */

header {
    height: 125px !important;
    padding: 0;
    margin: 0;

    #logo {
        position: absolute;
        top: 10px;
        left: 50%;
        margin-left: -585px;
        width: 400px;
        height: 100px !important;

        h1 {
            height: 100px !important;
            width: 100% !important;
            margin: 0;

            a {
                height: 100px !important;
                width: 100% !important;
                display: block;
                background-position: center center;
                background-repeat: no-repeat;

                span {
                    display: none;
                }
            }
        }

        @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
            margin-left: -485px;
            width: 350px;

            h1 {
                max-width: 350px;

                a {
                    max-width: 350px !important;
                    background-size: contain !important;
                }
            }
        }

        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            margin-left: ((@screen-sm-min / 2) * -1) + (@grid-gutter-size / 2);
            width: 350px;

            h1 {
                max-width: 350px;

                a {
                    max-width: 350px !important;
                    background-size: contain !important;
                }
            }
        }

        @media (max-width: @screen-xs-max) {
            left: 5px;
            margin-left: 0;
            width: 230px;

            h1 {
                max-width: 230px;

                a {
                    max-width: 230px !important;
                    background-size: contain !important;
                }
            }
        }
    }

    div.container {
        position: relative;

        #main-nav {
            position: absolute;
            top: 35px;
            left: auto;
            right: 0px;
            width: 715px;
            z-index: 10;

            @media (max-width: 991px) {
                width: 100%;
                top: 125px;
                left: 0px;

                .navbar {
                    overflow: visible;

                    .navbar-collapse {
                        overflow: hidden;
                        position: absolute;
                        width: 100%;
                        height: inherit;
                        top: 0px;
                        border-top: 0;
                        transition: height 0.35s ease 0s;

                        &.in {
                            overflow: visible;
                        }

                        .navbar-nav {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            height: auto;
                            width: 100%;
                            margin: 0px;

                            z-index: 1000;

                            li {
                                a {
                                    background: @dropdown-back-colour !important;
                                    color: @dropdown-fore-colour;
                                    line-height: 48px;
                                    padding: 0px 20px;
                                    border-bottom: none !important;

                                    &:hover {
                                        background: @dropdown-hover-back-colour !important;
                                        color: @dropdown-hover-fore-colour;
                                    }

                                    &::after {
                                        content: none;
                                    }
                                }


                                &:last-child {
                                    a {
                                        margin: 0px (@navbar-item-padding - 3px);
                                    }
                                }

                                &.active {
                                    a {
                                        background: @dropdown-active-back-colour !important;
                                        color: @dropdown-active-fore-colour;

                                        &:hover {
                                            background: @dropdown-hover-back-colour !important;
                                            color: @dropdown-hover-fore-colour;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .navbar {
                .navbar-brand {
                    position: relative;
                    display: block;
                    background: none;
                    padding: 0px 3px;
                    margin: 0px (@navbar-item-padding - 3px) 0px 0px;
                    font-size: 18px;
                    letter-spacing: 0px;

                    &::after {
                        content: '';
                        position: absolute;
                        display: block;
                        bottom: 10px;
                        left: 50%;
                        width: 0%;
                        margin-left: 0%;
                        height: 2px;
                        background: @navbar-active-fore-colour;

                        transition: width 200ms ease, margin-left 200ms ease;
                    }

                    &:hover, &:focus {
                        background: none;

                        &::after {
                            width: 100%;
                            margin-left: -50%;
                        }
                    }

                    &.navbar-brand-active {
                        background: none;
                        font-weight: bold;

                        &:hover, &:focus {
                            color: @navbar-active-fore-colour !important;
                            background: none;
                        }
                    }
                }

                .navbar-toggle {
                    background: @heading-colour;
                    right: 15px;
                    top: -85px;
                    width: 50px;

                    .icon-bar {
                        background: #fff;
                    }
                }

                .navbar-nav {
                    li {
                        a {
                            position: relative;
                            display: block;
                            background: none;
                            padding: 0px 3px;
                            margin: 0px (@navbar-item-padding - 3px);
                            font-size: 18px;
                            letter-spacing: 0px;

                            &::after {
                                content: '';
                                position: absolute;
                                display: block;
                                bottom: 10px;
                                left: 50%;
                                width: 0%;
                                margin-left: 0%;
                                height: 2px;
                                background: @navbar-active-fore-colour;

                                transition: width 200ms ease, margin-left 200ms ease;
                            }

                            &:hover, &:focus {
                                background: none;

                                &::after {
                                    width: 100%;
                                    margin-left: -50%;
                                }
                            }
                        }

                        &:last-child {
                            a {
                                margin-right: 0px;
                            }
                        }

                        &.active {
                            a {
                                background: none;
                                font-weight: bold;

                                &:hover, &:focus {
                                    color: @navbar-active-fore-colour;
                                    background: none;
                                }
                            }
                        }
                    }
                }
            }

            @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                width: 580px;
            }
        }
    }
}

section.banner {
    position: relative;
    margin-bottom: 40px;

    div#carousel-home {
        position: relative;
        min-height: 200px;

        .carousel-inner {
            overflow: hidden;

            .item {
                min-width: 700px;
                opacity: 0;
                transition: opacity 1s ease;
                -webkit-transition: opacity 1s ease;
                transform: none;

                img {
                    max-width: 105%;
                    min-height: 200px;
                }
            }

            .active {
                opacity: 1;
            }

            .active.left,
            .active.right {
                left: 0;
                opacity: 0;
                z-index: 1;
            }

            .next.left,
            .next.right {
                opacity: 1;
            }

            .prev.left,
            .prev.right {
                opacity: 1;
            }
        }

        .carousel-indicators {
            z-index: 3;
            text-align: right;
            .no-select();

            li {
                width: 12px;
                height: 12px;
                margin: 0px 3px;

                background: rgba(255, 255, 255, 0.5);
                border: 1px solid rgba(255, 255, 255, 0.2);

                transition: background-color 300ms ease, border-color 300ms ease;
                -webkit-transition: background-color 300ms ease, border-color 300ms ease;

                &:hover {
                    background: #ffffff;
                    border: 1px solid #ffffff;
                }

                &.active {
                    background: #ed1d24;
                    border: 1px solid #ed1d24;
                }
            }
        }

        .carousel-caption {
            position: absolute;
            z-index: 5;
            font-family: @heading-font, @heading-typography;
            font-size: 68px;
            text-align: right;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
            line-height: 60px;
        }
    }

    div#page-background {
        overflow: hidden;
        background: #000;

        img {
            opacity: 0.7;
            margin: 0 auto;
        }

        @media (max-width: 991px) {
            height: 200px;
        }
    }

    div.carousel-shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
        height: 100%;

        background: -webkit-linear-gradient(top,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 10%,rgba(0,0,0,0) 75%,rgba(0,0,0,0.4) 100%);
        background: linear-gradient(to bottom,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 10%,rgba(0,0,0,0) 75%,rgba(0,0,0,0.4) 100%);
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: @heading-font, @heading-typography;
}

h1.page-header,
div.page-header h1 {
    padding: 5px 0px 10px 0px;
    line-height: 31px;
    border-bottom: 1px solid @heading-colour;
    font-family: @heading-font, @heading-typography;
    font-weight: bold;
    font-size: 24px;
}

h2.page-header {
    padding: 5px 0px 10px 0px;
    border-bottom: 1px solid @heading-alt-colour;
    font-family: @heading-font, @heading-typography;
    font-size: 24px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: -1px;
}

div.page-header {
    border-bottom: none;
}

footer {
    font-size: 14px;
    min-height: 82px;
    line-height: 20px;
    padding: 0;

    img {
        vertical-align: middle;
    }

    .text {
        padding: 15px;

        @media (max-width: 767px) {
            text-align: center !important;
        }
    }

    .top-footer {
        background: #eaeaea;
        min-height: 150px;
        padding: 20px 0px;

        img {
            display: inline-block;
        }

        p {
            display: inline-block;
            margin-top: 3px;
            font-size: 1.2em;
            margin-right: 10px;
            color: @main-fore-colour;
        }

        span.glyphicon,
        span.social {
            margin-right: 5px;
        }

        .accreditations {
            padding: 10px 0;
        }

        @media (max-width: 991px) {
            .col-sm-12, .col-xs-12 {
                padding-top: 10px;
                text-align: center !important;
            }
        }
    }

    .bottom-footer {
        height: 82px;
        padding: 15px 0px 0px 0px;

        a {
            color: #ED1D24;

            &:hover {
                color: @footer-fore-colour;
            }
        }
    }
}

hr {
    border-top-color: #ddd;
}

.nav-tabs > li.active a,
.nav-tabs > li.active a:hover,
.nav-tabs > li.active a:focus {
    background: #eee;
    border-bottom: 1px solid #eee;
}

.tab-content {
    .tab-pane {
        background: #eee;
    }
}

.actions {
    background: #eee;
}

.article-grid .article-grid-container {
    background: #fff;
    border: 1px solid #ddd;
    height: 420px;
}

.testimonials,
.inner-testimonials {
    .testimonial {
        color: @heading-alt-colour;
        font-style: italic;
    }

    .testimonial-author {
        color: @heading-colour;
        margin-top: 10px;
    }
}

.inner-testimonials {
    margin-bottom: 15px;
}

.portfolio-slider {
    margin: 0 auto 20px auto;

    .slick-prev,
    .slick-next {
        width: 33px;
        height: 45px;
        position: absolute;
        top: 40%;
        left: -13px;
        overflow: hidden;
        text-indent: -9000px;
        z-index: 1000;

        border: none;
        background: url('/img/slide-previous-arrow.png') no-repeat top left;
    }

    .slick-next {
        left: auto;
        right: -8px;
        background: url('/img/slide-next-arrow.png') no-repeat top left;
    }

    .image {
        padding: 0px 5px 0px 0px;

        &.slick-active:last-of-type {
            padding: 0px;
        }
    }
}

.btn.btn-contact {
    display: inline-block;
    vertical-align: middle;
    background: @heading-colour;
    color: #fff;
    text-transform: uppercase;

    &.btn-large-icon {
        .glyphicon,
        .social {
            font-size: 1.5em;
            top: 0px;
        }
    }
}

.media {
    position: relative;
    overflow: visible;

    .media-item {
        position: relative;
        margin-bottom: 10px;
        cursor: pointer;

        .image {
            background: contrast(@main-back-colour, lighten(@main-back-colour, 2%), darken(@main-back-colour, 2%));
            border: 1px solid contrast(@main-back-colour, lighten(@main-back-colour, 14%), darken(@main-back-colour, 14%));
            width: 124px;
            height: 210px;
            padding: 1px;
            margin: 0 auto;

            canvas {
                opacity: 0.4;
                transition: opacity 0.75s ease;
                display: block;
            }

            &.uploaded canvas {
                opacity: 1;
            }
        }

        .name {
            background: @heading-colour;
            color: contrast(@heading-colour, #333, #efefef);
            font-size: 0.7em;
            text-align: center;
            line-height: 18px;
            height: 18px;
            margin: 1px 0px;
            overflow: hidden;
        }

        .tags {
            background: contrast(@main-back-colour, lighten(@main-back-colour, 20%), darken(@main-back-colour, 20%));
            height: 40px;

            .icon {
                height: 30px;
                padding: 10px 0px;
                width: 30px;

                float: left;
                text-align: center;
                color: contrast(@main-back-colour, lighten(@main-back-colour, 8%), darken(@main-back-colour, 8%));
            }

            .list {
                display: block;
                width: 90px;
                height: 32px;
                padding: 5px 0px;
                line-height: 0.65em;
                overflow: hidden;

                .label {
                    display: inline-block;
                    font-size: 0.65em;
                    padding: 1px 3px;
                    background: contrast(@main-back-colour, lighten(@main-back-colour, 50%), darken(@main-back-colour, 50%));
                    border-radius: 5px;
                    font-weight: normal;
                }
            }
        }

        .time {
            background: darken(#66afe9, 10%);
            color: contrast(darken(#66afe9, 10%));
            height:  25px;
            margin: 1px 0;

            &.unpublished {
                background: darken(#E9AA62, 10%);
                color: contrast(darken(#E9AA62, 10%));

                .icon {
                    color: contrast(darken(#E9AA62, 10%), lighten(darken(#E9AA62, 10%), 20%), darken(darken(#E9AA62, 10%), 20%));
                }
            }

            &.expired {
                background: darken(#D35757, 10%);
                color: contrast(darken(#D35757, 10%));

                .icon {
                    color: contrast(darken(#D35757, 10%), lighten(darken(#D35757, 10%), 20%), darken(darken(#D35757, 10%), 20%));
                }
            }

            .icon {
                height: 25px;
                padding: 2px 0px;
                width: 30px;

                float: left;
                text-align: center;
                color: contrast(darken(#66afe9, 10%), lighten(darken(#66afe9, 10%), 20%), darken(darken(#66afe9, 10%), 20%));
            }

            .status {
                display: inline-block;
                font-size: 0.65em;
                padding: 1px 3px;
                border-radius: 5px;
                font-weight: normal;
                float: left;
                margin: 1px 0px;
                line-height: 10px;
            }
        }

        &:hover .image {
            border: 1px solid #66afe9;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        }

        &.ui-sortable-helper {
            .image {
                border: 1px solid #66afe9;
                box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
            }
        }

        .loader {
            position: absolute;
            bottom: 10px;
            left: 50%;
            margin-left: -52px;
            width: 104px;
            height: 10px;
            border: 1px solid #333;
            background: #fff;

            .loaded {
                width: 0px;
                height: 8px;
                background: #449D44;
            }
        }
    }

    .media-item-placeholder {
        position: relative;
        margin-bottom: 10px;
        background: #F4F2FF;
        border: 1px dashed #D1D2E0;
        height: 210px;
        padding: 1px;
    }
}

.image-form-body {
    font-size: 0.9em;

    .form-group {
        margin-bottom: 0;
    }

    .form-control {
        font-size: 0.9em;
    }

    label {
        margin-top: 8px;
    }

    .image-preview {
        .image {
            background: #F4F2FF;
            border: 1px solid #66afe9;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            padding: 1px;
        }
    }

    .image-details {
        .row {
            margin-bottom: 5px;
        }
    }

    .select2-container .select2-selection {
        padding: 0;
    }
}

#media-help {
    .glyphicon.side {
        font-size: 5em;
        color: #aaa;
    }
}

.gallery-grid {
    .media-item {
        margin-bottom: 10px;

        a {
            display: block;
        }

        img {
            background: #fff;
            border: 1px solid #E0E0E0;
            padding: 1px;
            margin: 0 auto;

            &:hover {
                border: 1px solid #66afe9;
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.gallery-tags {
    .tag {
        .flatten();
        display: block;
        padding: 10px 15px;
        margin-top: 2px;
        cursor: pointer;
        text-decoration: none;
        background: @submenu-back-colour;
        color: @submenu-fore-colour;

        &:hover {
            background: @submenu-hover-back-colour;
            color: @submenu-hover-fore-colour;
        }
    }

    .tag.active {
        background: @submenu-active-back-colour;
        color: @submenu-active-fore-colour;

        &:hover {
            background: @submenu-hover-back-colour;
            color: @submenu-hover-fore-colour;
        }
    }
}
