// --- ABWEBCMS DEFAULT SITE STYLES
// --- Automatically generated by ABWeb CMS %%VERSION%%
// --- Time: %%TIME%%
// --- This file will be overwrited upon upgrades. Please do not make any changes here.

/*--------------------- MAIN */

html {
    & when (@sticky-footer = true) {
        height: 100%;
    }
}

body {
    background: @canvas-colour;

    & when (@sticky-footer = true) {
        height: 100%;
    }

    &.abweb-modal-open {
        overflow: hidden;
        width: 100%;
        animation: content-modal-open 0.35s ease normal forwards;
    }

    &.abweb-modal-closing {
        overflow: hidden;
        width: 100%;
        animation: content-modal-close 0.35s ease normal forwards;
    }
}

/*--------------------- TYPOGRAPHY */

strong,
b,
th,
label {
    font-weight: @content-bold-weight;
}

h1, h3, h5 {
    color: @heading-colour;
}
h2, h4, h6 {
    color: @heading-alt-colour;
}

/*--------------------- CONTAINERS */

div#wrap {
    background: @main-back-colour;

    & when (@sticky-footer = true) {
        height: auto;
        margin: 0 auto ((@footer-height) * -1);
        min-height: 100%;
        padding: 0 0 (@footer-height + 25px);
    }
}

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.01;
    display: none;
    z-index: 2001;

    transition: opacity 0.35s ease;

    &.overlay-showing {
        opacity: 0.7;
    }

    &.overlay-hiding {
        opacity: 0.01;
    }
}

/*--------------------- FOOTER */

footer {
    background: @footer-back-colour;
    color: @footer-fore-colour;
    min-height: @footer-height;
    padding: 10px 0px;

    div.col-xs-6 {
        background: @footer-back-colour;
    }
}

/*--------------------- CONTENT */

noscript {
    display: block;
}

.page-header {
    clear: both;

    &:first-child {
        margin-top: 0;
    }
}

/*--------------------- BLOCKS */

.well {
    .border-radius(@border-radius-large);
    box-shadow: none;
}

.alert {
    animation: alert-slidedown 0.75s normal;

    &.alert-fixed-top {
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);

        & when (@navbar-position = 'fixed-top') {
            margin-top: @navbar-height;
        }
    }
}

body.cms-menu-visible {
    .alert-fixed-top {
        margin-top: 50px;

        & when (@navbar-position = 'fixed-top') {
            margin-top: 50px + @navbar-height;
        }
    }
}

.modal {
    top: 50px;
}

/*--------------------- IMAGES */

.fr-dii {
    margin: @image-margin-size 0 !important;

    &.fr-fil {
        margin: @image-margin-size @image-margin-size @image-margin-size 0 !important;
    }

    &.fr-fir {
        margin: @image-margin-size 0 @image-margin-size @image-margin-size !important;
    }
}

img.img-rounded {
    border-radius: @image-rounding-size;
}

img.img-bordered {
    padding: @image-border-padding;
    border: @image-border-size @image-border-type @image-border-colour;
}

img.img-more-padding,
img.img-more-padding.fr-dii {
    @image-margin-size-2x: (@image-margin-size * 2);

    margin: @image-margin-size-2x 0 !important;

    &.fr-fil {
        margin: @image-margin-size-2x @image-margin-size-2x @image-margin-size-2x 0 !important;
    }

    &.fr-fir {
        margin: @image-margin-size-2x 0 @image-margin-size-2x @image-margin-size-2x !important;
    }
}

img {
    &.fr-dib {
      margin: @image-margin-size auto;
      display: block;
      float: none;
      vertical-align: top;

      &.fr-fil {
        margin-left: 0;
      }

      &.fr-fir {
        margin-right: 0;
      }
    }

    &.fr-dii {
      display: inline-block;
      float: none;
      vertical-align: bottom;

      &.fr-fil {
        float: left;
      }

      &.fr-fir {
        float: right;
      }
    }
}

img.img-no-top-margin,
img.img-no-top-margin.fr-dii,
img.img-no-top-margin.fr-dii.fr-fil,
img.img-no-top-margin.fr-dii.fr-fir {
    margin-top: 0px !important;
}

img.img-no-bottom-margin,
img.img-no-bottom-margin.fr-dii,
img.img-no-bottom-margin.fr-dii.fr-fil,
img.img-no-bottom-margin.fr-dii.fr-fir {
    margin-bottom: 0px !important;
}

img.img-collapse-on-mobile,
img.img-collapse-on-mobile.fr-dii,
img.img-collapse-on-mobile.fr-dii.fr-fil,
img.img-collapse-on-mobile.fr-dii.fr-fir  {
    @media (max-width: 767px) {
        display: block !important;
        float: none !important;
        margin: @image-margin-size auto !important;
    }
}

/*--------------------- FORMS AND CONTROLS */

fieldset {
    legend {
        line-height: (@content-size * 1.5) * 1.66;
    }
}

.form-group {
    margin-bottom: 20px;

    &.short-margin {
        margin-bottom: 2px;
    }

    &.has-feedback.has-error,
    &.has-feedback.has-warning {
        animation: field-error-margin 150ms ease-out normal forwards;
    }

    div.error-message {
        &:extend(.label);
        &:extend(.label-danger);
    }

    .form-control ~ span.label,
    .form-control ~ div.error-message,
    .input-group ~ span.label,
    .input-group ~ div.error-message {
        display: block;
        height: 0px;
        line-height: 15px;
        padding: 0px 8px;
        overflow: hidden;

        animation: show-error 150ms ease-out normal forwards;

        text-align: left;
    }
}

.form-control {
    box-shadow: none;

    &::-moz-placeholder {
        color: contrast(@form-back-colour, lighten(@form-back-colour, 25%), darken(@form-back-colour, 25%));
        opacity: 1;
    }
    &::-ms-input-placeholder {
        color: contrast(@form-back-colour, lighten(@form-back-colour, 25%), darken(@form-back-colour, 25%));
        opacity: 1;
    }
    &::-webkit-input-placeholder {
        color: contrast(@form-back-colour, lighten(@form-back-colour, 25%), darken(@form-back-colour, 25%));
        opacity: 1;
    }
    &::-input-placeholder {
        color: contrast(@form-back-colour, lighten(@form-back-colour, 25%), darken(@form-back-colour, 25%));
        opacity: 1;
    }
}

.input-group {
    .input-group-addon {
        .bordered(@form-border-colour);
        border-right: none;

        .glyphicon,
        .social {
            font-size: 1.3em;
            top: 0px;
        }
    }
}

.has-error {
    .form-control {
        box-shadow: none;
    }

    .input-group {
        .input-group-addon {
            .bordered(@state-danger-text);
            border-right: none;
        }
    }

    .select2-container {
        .select2-selection {
            .bordered(@state-danger-text);
        }
    }

    .bootstrap-switch {
        .bordered(@state-danger-text);
    }

    .checkbox {
        label {
            .gradient(lighten(multiply(@colour-danger, #222222), 10%), lighten(multiply(@colour-danger, #45484d), 20%), 'vert');
        }
    }
}

.has-success {
    .form-control {
        box-shadow: none;
    }

    .input-group {
        .input-group-addon {
            .bordered(@state-success-text);
            border-right: none;
        }
    }

    .select2-container {
        .select2-selection {
            .bordered(@state-success-text);
        }
    }

    .bootstrap-switch {
        .bordered(@state-success-text);
    }
}

.has-warning {
    .form-control {
        box-shadow: none;
    }

    .input-group {
        .input-group-addon {
            .bordered(@state-warning-text);
            border-right: none;
        }
    }

    .select2-container {
        .select2-selection {
            .bordered(@state-warning-text);
        }
    }

    .bootstrap-switch {
        .bordered(@state-warning-text);
    }

    .checkbox {
        label {
            .gradient(lighten(multiply(@colour-warning, #222222), 10%), lighten(multiply(@colour-warning, #45484d), 20%), 'vert');
        }
    }
}

.has-feedback {
    label~.form-control-feedback {
        &:extend(.animated, .bounceIn);

        top: 37px;
        opacity: 0.5;

        &.glyphicon {
            position: absolute;
        }
    }
}

.btn {
    text-decoration: none;

    &.btn-labeled {
        .glyphicon {
            top: 0;
            line-height: inherit;
        }
    }
}

.checkbox {
    width: 28px;
    height: 28px;
    position: relative;
    margin: 20px auto;

    &.inline {
        display: inline-block;
        margin: 0;
        top: 1px;
    }

    &.left {
        float: left;
        margin-right: 15px;
    }

    label {
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        .gradient(#222222, #45484d, 'vert');
        border-radius: 4px;

        &::after {
            content: '';
            width: 5px;
            height: 3px;
            position: absolute;
            top: 5px;
            left: 4px;
            border: 3px solid #fcfff4;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-75deg);

            transition: opacity 175ms ease, height 175ms ease, width 175ms ease, transform 175ms ease;
        }

        &:hover {
            &::after {
                width: 12px;
                height: 8px;
                opacity: 0.3;
                transform: rotate(-45deg);
            }
        }
    }

    input[type=checkbox] {
        visibility: hidden;

        &:checked + label {
            .gradient(lighten(multiply(@colour-success, #222222), 10%), lighten(multiply(@colour-success, #45484d), 20%), 'vert');
        }

        &:checked + label:after {
            width: 12px;
            height: 8px;
            opacity: 1;
            transform: rotate(-45deg);
        }
    }
}

.bootstrap-switch {
    &.bootstrap-switch-focused {
        box-shadow: none !important;
        border: 1px solid @form-focus-border-colour;
    }
    &.bootstrap-switch-small .bootstrap-switch-handle-on,
    &.bootstrap-switch-small .bootstrap-switch-handle-off,
    &.bootstrap-switch-small .bootstrap-switch-label {
        padding: 5px;
    }

    .form-control-feedback {

    }
}

.switch-label {
    padding-top: 5px;
    cursor: pointer;
}

.tooltip {
    padding: 8px 10px;
    font-weight: @content-normal-weight !important;
}

.thumbnail {
    .border-radius(@border-radius-base);
}

.select2-container.select2-inline {
    width: auto;
    display: inline-block;
}

.select2-container {
    .select2-dropdown {
        .select2-results {
            .select2-results__option {
                background: @form-back-colour;
                color: @form-fore-colour;
                border-left: 5px solid @form-back-colour;

                &:hover {
                    background: @select2-hover-back-colour;
                    color: @select2-hover-fore-colour;
                    border-left: 5px solid @select2-hover-back-colour;
                }
            }

            .select2-results__option[aria-selected=true] {
                background: @select2-selected-back-colour;
                color: @select2-selected-fore-colour;
                border-left: 5px solid @select2-selected-border-colour;

                &:hover {
                    background: @select2-hover-back-colour;
                    color: @select2-hover-fore-colour;
                    border-left: 5px solid @select2-selected-border-colour;
                }
            }
        }
    }

    &.select2-container--open {
        .select2-selection {
            &.select2-selection--single {
                .select2-selection__arrow {
                    b {
                        border-width: 0 5px 6px 5px;
                        border-color: transparent transparent #66afe9 transparent;
                    }
                }
            }
        }
    }
}

.bootstrap-datetimepicker-widget {
    background: @form-back-colour;
    color: @form-fore-colour;
    border: 1px solid @form-border-colour;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);

    &.dropdown-menu.bottom {
        margin-top: 5px;

        &::before {
            border-bottom: 7px solid @form-border-colour;
        }
    }

    table {
        td, th {
            &.day:hover {
                background: darken(@form-back-colour, 10%);
            }

            &.active,
            &.active:hover {
                background: @link-colour;
                color: contrast(@link-colour);
            }
        }
    }
}

.actions {
    margin: 10px 0px;
}

.fileinput {
    .thumbnail {
        color: @input-color;
        background-color: @input-bg;
        background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
        border: 1px solid @input-border;
        border-radius: @input-border-radius;
        cursor: pointer;
        position: relative;
        width: 250px;
        height: 150px;
        line-height: 146px;

        & > img,
        & > canvas {
            position: relative;
            max-width: 100%;
            z-index: 12;
        }

        &:before:extend(.glyphicon) {
            position: absolute;
            top: 48% !important;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            margin-top: -64px;
            content: "\E139";
            font-size: 128px;
            color: darken(@input-bg, 4%);
            z-index: 9;
        }
    }

    &.fileinput-exists {
        .thumbnail {
            &[data-filename$=".png"],
            &[data-filename$=".PNG"] {
                background: url('/img/included/empty.gif') repeat top left;
            }

            &:before {
                content: none;
            }
        }
    }
}

.nope {
    display: none;
}

/*----------------------- TABLES */

table.table-flex {
    @media (max-width: 767px) {
        thead {
            display: none !important;
        }

        tr {
            display: block !important;
            border-bottom: 2px solid @abweb-table-border;

            &:last-child {
                border-bottom: 0;
            }

            td, th {
                display: block !important;
                position: relative;
                width: 100% !important;
                text-align: left;
                min-height: 38px;

                &[data-header] {
                    padding-left: 33%;

                    &::before {
                        content: attr(data-header);
                        display: block;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 30%;
                        height: 100%;
                        padding: 8px;

                        background: @abweb-table-th-back;
                        border-right: 1px solid @abweb-table-border;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

table.table-flex-content {
    width: 100%;
    margin: 0 0 @content-size;

    td, th {
        padding: 0px (@grid-gutter-size / 2);

        &:first-child {
            padding-left: 0px;
        }

        &:last-child {
            padding-right: 0px;
        }
    }

    &.collapse-on-sm {
        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            .collapsible-table();
        }
    }

    &.collapse-on-md {
        @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
            .collapsible-table();
        }
    }

    @media (max-width: @screen-xs-max) {
        .collapsible-table();
    }
}

/*----------------------- TABS */

.nav-tabs > li > a {
    .glyphicon,
    .social {
        margin: 0px 6px;
    }
}

.nav-tabs > li > a.error,
.nav-tabs > li > a.has-error {
    background: contrast(@main-back-colour, lighten(overlay(lighten(@main-back-colour, 7%), @colour-danger), 3%), darken(overlay(darken(@main-back-colour, 7%), @colour-danger), 3%));
    color: @colour-danger;
}

.nav-tabs > li.active a,
.nav-tabs > li.active a:hover,
.nav-tabs > li.active a:focus {
    outline: none;
}

.tab-content {
    .tab-pane {
        padding: 15px;
        background: contrast(@main-back-colour, lighten(@main-back-colour, 1%), darken(@main-back-colour, 1%));
        border: 1px solid contrast(@main-back-colour, lighten(@main-back-colour, 12%), darken(@main-back-colour, 12%));
        border-top: none;
    }

    .tab-no-border {
        padding: 15px 0px;
        background: none;
        border: none;
    }
}

.nav-tabs {
    .nav-tab-full-border.active {
        a,
        a:hover,
        a:focus {
            border-bottom-color: 1px solid contrast(@main-back-colour, lighten(@main-back-colour, 12%), darken(@main-back-colour, 12%));
            outline: none;
        }
    }
}

@media (max-width: 767px) {
    .nav-tabs {
        float: left;
        width: 25%;
        border-bottom: none;

        li {
            float: none;

            a,
            a:hover,
            a:focus {
                padding: 10px 8px;
                margin: 0px 0px 4px 0px;
                border: 1px solid contrast(@main-back-colour, lighten(@main-back-colour, 12%), darken(@main-back-colour, 12%));
                border-right: none;
            }

            &.active {
                a,
                a:hover,
                a:focus {
                    border-bottom: 1px solid contrast(@main-back-colour, lighten(@main-back-colour, 12%), darken(@main-back-colour, 12%));
                    border-right: none;
                }
            }
        }
    }

    .tab-content {
        float: right;
        width: 75%;

        .tab-pane {
            border-top: 1px solid contrast(@main-back-colour, lighten(@main-back-colour, 12%), darken(@main-back-colour, 12%));
        }
    }
}

/*--------------------- STAMPS */

.label {
    font-weight: @content-bold-weight;
}

.glyphicon,
.social {
    top: 3px;
}

/*--------------------- FROALA OVERRIDES */

.fr-box {
    position: relative;
    padding: 0;
}

.froala-editor {
    .heir {
        opacity: 0.3;
    }
}

.abwebcms-theme {
    .fr-toolbar, .fr-popup {
        .fr-command.fr-btn {
            background: @btn-bg;

            i {
                top: 0;
            }
        }
    }

    .fr-element {
        padding: 15px;

        & * {
            box-sizing: border-box;
        }

        table.table-flex-content {
            margin: 0;

            td, th {
                padding: 5px;
                border: 1px dotted #D6D6E5;
                background: #FBFBFF;
            }
        }

        div[class^="col-"] {
            border: 1px dotted #D6D6E5;
            background: #FBFBFF;
        }

        div[class^="col-"]:first-child {
            border-left: none;
        }

        div[class^="col-"]:last-child {
            border-right: none;
        }
    }

    .fr-command.fr-btn {
        + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content {
            background: @froala-widget-back-colour;
            color: @froala-widget-fore-colour;
            border: 1px solid @froala-widget-border-colour;

            .fr-command {
                .glyphicon {
                    top: 6px;
                }
                &:hover, &:focus {
                    background: @froala-widget-hover-back-colour;
                    color: @froala-widget-hover-fore-colour;
                }
                &.fr-active {
                    background: @froala-widget-active-back-colour;
                    color: @froala-widget-active-fore-colour;
                }
            }
        }
    }

    .fr-popup,
    .fr-popup.fr-desktop {
        background: @froala-widget-back-colour;
        color: @froala-widget-fore-colour;
        border: 1px solid @froala-widget-border-colour;
        top: 37px !important;

        .fr-arrow {
            border-left-width: 8px;
            border-right-width: 8px;
            border-bottom-width: 9px;
        }

        &.fr-above .fr-arrow {
            border-top-width: 9px;
        }

        .fr-buttons {
            background: @froala-ui-colour;
        }
    }
}

.fr-popup.abwebcms-theme,
.fr-popup.fr-desktop.abwebcms-theme {
    background: @froala-widget-back-colour;
    color: @froala-widget-fore-colour;
    border: 1px solid @froala-widget-border-colour;

    .fr-arrow {
        border-left-width: 8px;
        border-right-width: 8px;
        border-bottom-width: 9px;
    }

    &.fr-above .fr-arrow {
        border-top-width: 9px;
    }

    .fr-buttons {
        background: @froala-ui-colour;
    }
}

/*--------------------- MODAL */

.abweb-modal {
    position: fixed;
    width: 600px;
    background: #ffffff;
    box-shadow: 4px 4px 8px rgba(0,0,0,0.4);
    display: none;
    z-index: 2002;

    .abweb-modal-body {
        padding: 15px;
    }

    .abweb-modal-header,
    .abweb-modal-footer {
        padding: 10px 15px;
    }

    .abweb-modal-header {
        background: @abweb-grey;
        color: #ffffff;
        position: relative;

        p, strong, h1, h2, h3, h4, h5, h6 {
            font-size: 1em;
            font-weight: @content-bold-weight;
            color: #ffffff;
            margin: 0;
        }

        button[data-close-modal] {
            position: absolute;
            top: 0px;
            right: 0px;
            height: 100%;
            line-height: 100%;
            width: 40px;
            color: #ffffff;
            background: transparent;
            border: none;
            font-size: 1.5em;
            font-weight: @content-bold-weight;

            transition: background-color 175ms ease;

            &:hover {
                background: @colour-danger;
            }
        }
    }

    .abweb-modal-footer {
        background: #f4f4f4;
        border-top: 1px solid #ddd;
    }

    &.modal-open {
        position: fixed;
        animation: modal-open 0.35s ease normal forwards;
    }

    &.modal-closing {
        position: fixed;
        animation: modal-close 0.35s ease normal forwards;
    }
}

#crop-image {
    .image-crop {
        img {
            max-width: 100%;
        }

        .cropper-container {
            .cropper-modal {
                opacity: 0.85;
            }
        }
    }
}
